import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'

import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Paper,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Notifier from '../components/Notifier'
import {
    setEmergencyNumber,
    getEmergencyNumber,
} from '../redux/actions/settings'

const useStyles = makeStyles(theme => ({
    root: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
    },
    paper: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '350px',
        '& div': {
            '&:first-child': {
                flexGrow: 1,
                margin: theme.spacing(2),
            },
        },
    },
}))

const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

export default () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const emergencyPhone = useSelector(state => state.settings.emergency_number)

    // region
    const formik = useFormik({
        initialValues: {
            emergency_number: '',
        },
        validationSchema: () => {
            return Yup.object({
                emergency_number: Yup.string().matches(
                    phoneRegexp,
                    'Phone number is invalid'
                ),
            })
        },
        onSubmit: values => {
            const { emergency_number } = values

            dispatch(setEmergencyNumber(emergency_number))
        },
    })
    // endregion

    useEffect(() => {
        emergencyPhone
            ? formik.setFieldValue('emergency_number', emergencyPhone, false)
            : dispatch(getEmergencyNumber())
    }, [emergencyPhone])

    return (
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Notifier />
            <Grid container className={classes.root}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper className={classes.paper}>
                        <TextField
                            id="emergency_number"
                            name="emergency_number"
                            label="Emergency phone number"
                            placeholder="Emergency phone number"
                            variant="outlined"
                            margin="dense"
                            autoComplete="phone"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.input}
                            error={formik.errors.emergency_number && true}
                            {...formik.getFieldProps('emergency_number')}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            save
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    )
}
