import axios from 'axios'
import Config from '../config'

const powBackendAPI = axios.create({
    baseURL: `${Config.API_URL}`,
    headers: {
        'x-access-token': `${window.localStorage.getItem('access_token')}`,
    },
})

export const getPowBackendAPI = () => powBackendAPI

export const parseGetParams = params => obj2query(params)

export const obj2query = params => {
    const query =
        '?' +
        Object.keys(params)
            .map(k => `${k}=${encodeURIComponent(params[k])}`)
            .join('&')
    return query
}
