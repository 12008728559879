import actions from './actionsKeys'

export const createUser = body => ({
    type: actions.CREATE_USER,
    payload: body,
})

export const updateUser = body => ({
    type: actions.UPDATE_USER,
    payload: body,
})

export const listUsers = (status, filter, offset, limit, sort) => ({
    type: actions.GET_USERS_LIST,
    payload: {
        status,
        filter,
        offset,
        limit,
        sort,
    },
})

export const getUsersByRole = id => ({
    type: actions.GET_USERS_BY_ROLE,
    payload: { id },
})

export const getUser = id => ({
    type: actions.GET_USER,
    payload: { userId: id },
})

export const getRoles = () => ({
    type: actions.GET_ROLES,
})

export const resetCurrentUser = () => ({
    type: actions.RESET_USER,
})

export const blockUser = userId => ({
    type: actions.BLOCK_USER,
    payload: {
        userId,
    },
})

export const deleteUser = userId => ({
    type: actions.DELETE_USER,
    payload: {
        userId,
    },
})

export const activateUser = userId => ({
    type: actions.ACTIVATE_USER,
    payload: {
        userId,
    },
})

export const requestPassword = (username, email) => ({
    type: actions.REQUEST_PASSWORD,
    payload: {
        username,
        email,
    },
})
