import React, { PureComponent, useEffect } from 'react'
import TopBar from '../components/TopBar'
import BookingsList, { BookingToolbar } from '../components/BookingsList'
import { Grid, Divider, Breadcrumbs, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { push } from '../redux/actions/notifier'

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(3),
    },
}))

const MyBookingsPage = () => {
    const classes = useStyles()
    const activeRole = useSelector(state => state.auth.user.role_name)
    const dispatch = useDispatch()

    const bookingMessages = useSelector(state => state.booking.messages)
    useEffect(() => {
        bookingMessages.confirm && dispatch(push(bookingMessages.confirm))
        bookingMessages.create && dispatch(push(bookingMessages.create))
    }, [bookingMessages])

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <TopBar />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Current bookings
                </Typography>
                <BookingToolbar showRoleSelector={true} />
            </Grid>
            <Grid item xs={12}>
                <BookingsList type="current" />
            </Grid>
            <Divider className={classes.divider} light />

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Past bookings
                </Typography>
                <BookingToolbar type="past" />
            </Grid>
            <Grid item xs={12}>
                <BookingsList type="past" />
            </Grid>
        </Grid>
    )
}

export default MyBookingsPage
