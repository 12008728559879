import {
    AppBar,
    Grid,
    Box,
    Typography,
    Tabs,
    Tab,
    Toolbar,
    IconButton,
} from '@material-ui/core'
import { AccountCircle, People, Settings } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'

import { LogoutIconButton } from './LogoutButton'

import logo from '../assets/contact.png'

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    }
}

function LinkTab(props) {
    const history = useHistory()
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault()
                history.push(props.href)
            }}
            {...props}
        />
    )
}

const UsersLinkIconButton = () => {
    const history = useHistory()
    const handleAccountLink = useCallback(() => {
        history.push('/u/users')
    }, [])

    return (
        <IconButton
            edge="end"
            aria-label="users management"
            aria-controls={0}
            aria-haspopup="true"
            onClick={handleAccountLink}
            color="inherit"
            title="Users Management"
        >
            <People />
        </IconButton>
    )
}

const SettingsLinkIconButton = () => {
    const history = useHistory()
    const handleAccountLink = useCallback(() => {
        history.push('/u/settings')
    }, [])

    return (
        <IconButton
            edge="end"
            aria-label="my account"
            aria-controls={0}
            aria-haspopup="true"
            onClick={handleAccountLink}
            color="inherit"
            title="Settings"
        >
            <Settings />
        </IconButton>
    )
}

const useStyles = makeStyles(theme => ({
    topBar: {
        backgroundColor: '#fff',
        width: '100%',
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexGrow: '1',
        paddingRight: theme.spacing(1),
    },
    title: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        color: 'black',
        '&:link': {
            color: 'black',
        },
        padding: theme.spacing(0, 0, 0, 2),
    },
    logo: { maxHeight: '40px', '&a:hover': { textDecoration: 'none' } },
    statusText: {},
}))

export default function TopBar() {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const { role_name, firstname, lastname, username } = useSelector(
        state => state.auth.user
    )
    const currentRole =
        role_name &&
        role_name
            .split('_')
            .map(r => `${r.charAt(0).toUpperCase()}${r.slice(1)}`)
            .join(' ')

    const location = useLocation()

    useEffect(() => {
        const { pathname } = location
        if (pathname.startsWith('/u/admin-home')) {
            setValue(0)
        }
        if (pathname.startsWith('/u/my-bookings')) {
            setValue(1)
        }
        if (pathname.startsWith('/u/commercial-bookings')) {
            setValue(2)
        }
        if (pathname.startsWith('/u/operations-bookings')) {
            setValue(3)
        }
        if (pathname.startsWith('/u/vts')) {
            setValue(4)
        }
        return () => {}
    }, [location])

    return (
        <AppBar position="static" className={classes.topBar} position="fixed">
            <Grid container direction="row">
                <Grid item className={classes.title}>
                    <Typography variant="h6">
                        <a
                            href="https://www.purpletowing.com"
                            className={classes.title}
                        >
                            <img src={logo} className={classes.logo} />
                            <span style={{ margin: '0 0 0 16px' }}>
                            Purple water towing ltd <small>v{global.appVersion}</small>
                            </span>
                        </a>
                    </Typography>
                </Grid>
                <Grid item className={classes.icons}>
                    <Typography
                        variant="caption"
                        className={classes.statusText}
                    >
                        Logged in as: {`${username}, ${currentRole}`}
                    </Typography>
                    {role_name === 'admin' && <UsersLinkIconButton />}
                    {role_name === 'admin' && <SettingsLinkIconButton />}
                    <LogoutIconButton />
                </Grid>
            </Grid>
        </AppBar>
    )
}
