const nextbookings = {
  labels: [{ id: 'mmsi', label: 'MMSI IMO' },
  { id: 'flag', label: 'Flag' },
  { id: 'length', label: 'Length' },
  { id: 'beam', label: 'Beam' },
  { id: 'draft', label: 'Draft' },
  { id: 'gross', label: 'Gross Tonnage' },
  { id: 'net', label: 'Net Tonnage' },
  { id: 'owner', label: 'Owner' },
  { id: 'eta', label: 'ETA' },
  { id: 'etd', label: 'ETD' },
  { id: 'agent', label: 'Agent' },
  { id: 'booking_received', label: 'Booking Received' },
  { id: 'operations_confirmed', label: 'Operations Confirmed' }],
  data: [{
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  }]
}

const pastbookings = {
  labels: [{ id: 'mmsi', label: 'MMSI IMO' },
  { id: 'flag', label: 'Flag' },
  { id: 'length', label: 'Length' },
  { id: 'beam', label: 'Beam' },
  { id: 'draft', label: 'Draft' },
  { id: 'gross', label: 'Gross Tonnage' },
  { id: 'net', label: 'Net Tonnage' },
  { id: 'owner', label: 'Owner' },
  { id: 'eta', label: 'ETA' },
  { id: 'etd', label: 'ETD' },
  { id: 'agent', label: 'Agent' },
  { id: 'booking_received', label: 'Booking Received' },
  { id: 'operations_confirmed', label: 'Operations Confirmed' }],
  data: [{
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  },
  {
    'mmsi': 'this and that',
    'flag': 'red flag',
    'length': '100ft',
    'beam': '10ft',
    'draft': '234',
    'gross': '2324',
    'net': '2000',
    'owner': 'That Guy',
    'eta': '2020/01/01 03:30 am',
    'etd': '2020/01/01 03:30 am',
    'agent': 'That Agent',
    'booking_received': '',
    'operations_confirmed': ''
  }]
}

export {
  nextbookings,
  pastbookings
}
