import { parseGetParams, obj2query } from '../utils/Http'
import { jsonCall } from './Gateway'

export const setEmergencyNumber = emergency_number => {
    const jwtResp = jsonCall(`/settings/emergency_number`, 'PUT', {
        emergency_number,
    })
    return jwtResp
}

export const getEmergencyNumber = () => {
    const jwtResp = jsonCall(`/settings/emergency_number`, 'GET')
    return jwtResp
}
