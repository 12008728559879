import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    TextField,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { listUsers } from '../redux/actions/user'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    center: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    checkboxes: {
        margin: theme.spacing(3),
    },
    formGroup: {
        padding: theme.spacing(1, 3, 1, 3),
    },
    divider: {
        margin: theme.spacing(3),
    },
}))

const roleLabels = {
    ship_owner: 'Ship owner',
    agent: 'Agent',
}

const ClientsDropdown = ({ formik, fieldName, label, helperText }) => {
    const classes = useStyles()
    const bookingData = useSelector(state => state.booking.currentBooking)

    const dispatch = useDispatch()
    const users = useSelector(state => state.user.usersList)

    const loadUsers = useCallback(() => {
        dispatch(listUsers(1, null, null, null, null))
    }, [dispatch])

    useEffect(() => {
        loadUsers()
    }, [dispatch, loadUsers])

    return (
        <Grid container className={classes.formGroup}>
            {formik && fieldName && (
                <Grid item xs={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id={`${fieldName}-label`}>
                            {label}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId={`${fieldName}-label`}
                            id={fieldName}
                            name={fieldName}
                            error={formik.errors[fieldName] && true}
                            {...formik.getFieldProps(fieldName)}
                        >
                            <MenuItem value="">Unassigned</MenuItem>
                            {users &&
                                users
                                    .filter(
                                        u =>
                                            ['agent', 'ship_owner'].indexOf(
                                                u.role.name
                                            ) > -1
                                    )
                                    .map(user => (
                                        <MenuItem
                                            key={`agent_${user.user_id}`}
                                            disabled={false}
                                            selected={
                                                bookingData &&
                                                bookingData.user &&
                                                user.user_id ===
                                                    bookingData[fieldName]
                                            }
                                            value={user.user_id}
                                        >
                                            {roleLabels[user.role.name]},
                                            {user.username},{user.firstname},
                                            {user.lastname}
                                        </MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    )
}

export default ClientsDropdown
