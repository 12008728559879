import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import { push } from '../redux/actions/notifier'
import { confirmBooking } from '../redux/actions/booking'
import { resetNotifier } from '../redux/actions/notifier'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    confirm: {
        color: theme.palette.green[50],
        backgroundColor: theme.palette.green[500],
    },
    reject: {
        color: theme.palette.red[50],
        backgroundColor: theme.palette.red[500],
    },
    close: {
        color: theme.palette.grey[50],
        backgroundColor: theme.palette.grey[500],
    },
}))

const OperationsConfirmButton = ({ bookingData, type }) => {
    const [open, setOpen] = React.useState(false)
    const [rejectOpen, setRejectOpen] = React.useState(false)
    const dispatch = useDispatch()
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setRejectOpen(false)
    }

    const handleConfirm = () => {
        setOpen(false)
        dispatch(
            confirmBooking({
                bookingId: bookingData.booking_id,
                confirmed: true,
            })
        )
        setTimeout(() => {
            dispatch(resetNotifier())
        }, 5000)
    }

    const handleReject = () => {
        setOpen(false)
        setRejectOpen(true)
    }

    const handleConfirmReject = () => {
        setRejectOpen(false)
        dispatch(
            confirmBooking({
                bookingId: bookingData.booking_id,
                confirmed: false,
            })
        )
        setTimeout(() => {
            dispatch(resetNotifier())
        }, 5000)
    }
    const handleAbortReject = () => {
        setRejectOpen(false)
    }

    return (
        <div>
            <ButtonGroup>
                {type !== 'past' && (
                    <Button onClick={handleReject} className={classes.reject}>
                        Reject
                    </Button>
                )}
                {type !== 'past' &&
                    (bookingData.status === 'pending' ||
                        bookingData.status === 'received') && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            Confirm
                        </Button>
                    )}
            </ButtonGroup>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Booking's Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to confirm this booking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.close}>
                        close
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        className={classes.confirm}
                        autoFocus
                    >
                        Confirm booking
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                id="alert-confirm-reject"
                open={rejectOpen}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {'Reject booking'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to reject this booking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.close}>
                        close
                    </Button>
                    <Button
                        onClick={handleAbortReject}
                        className={classes.reject}
                    >
                        NO
                    </Button>
                    <Button
                        onClick={handleConfirmReject}
                        className={classes.confirm}
                        autoFocus
                    >
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default OperationsConfirmButton
