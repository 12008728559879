import React, { PureComponent } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoginForm from '../components/LoginForm'
import RequestPasswordForm from '../components/RequestPasswordForm'

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    red: {
        color: 'red',
    },
}))

const LoginPage = () => {
    const { message } = useParams()
    const classes = useStyles()

    return (
        <Grid container xs={12} justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}></Grid>
            <Grid item xs={12} className={classes.message}>
                <Typography variant="h6">
                    Welcome to Purple Water Towing. {message}
                    <br/>
                    <small>v{ global.appVersion }</small>
                </Typography>
            </Grid>
            {message && (
                <Grid item xs={12} className={classes.message}>
                    {message === 'confirm' && (
                        <Typography variant="body1">
                            Your account has been activated, please login using
                            username and password we sent to your email address.
                        </Typography>
                    )}
                    {message === 'reset' && (
                        <Typography variant="body1">
                            Your password has been reset as requested.
                            <br />
                            Please login using credentials provided by email.
                        </Typography>
                    )}
                    {message === 'expired' && (
                        <Typography variant="body1">
                            Your token has expired.
                            <br />
                            Please request a new password and activate it in one
                            hour.
                        </Typography>
                    )}
                    {message === 'failure' && (
                        <Typography variant="body1" className={classes.red}>
                            Wrong username or password.
                        </Typography>
                    )}
                </Grid>
            )}
            <Grid item xs={12}>
                <LoginForm />
            </Grid>
            <Grid item xs={12}>
                <RequestPasswordForm />
            </Grid>
        </Grid>
    )
}

export default LoginPage
