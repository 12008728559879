import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const API_URL = process.env[`REACT_APP_API_URL`]

export const prefetch = cb => {
    if (!cookies.get('csrfToken')) {
        fetch(`${API_URL}/csrf`, {
            method: 'GET',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(resp => {
            resp.json().then(json => {
                cookies.set('csrfToken', json.csrfToken, { path: '/' })
                cb()
            })
        })
    } else cb()
}

export const jsonCall = async (url, method, data) => {
    let csrf
    switch (method) {
        case 'POST':
        case 'DELETE':
        case 'PUT':
            csrf = cookies.get('csrfToken')
            break
        default:
            csrf = null
            break
    }

    const raw = await fetch(`${API_URL}${url}`, {
        method,
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            ...(csrf ? { 'csrf-token': csrf } : {}),
        },
        credentials: 'include',
        ...(data ? { body: JSON.stringify(data) } : {}),
    })

    const resp = await raw.json()
    if (raw.status === 401) {
        return {
            success: false,
            code: 401,
            error: resp,
        }
    } else return resp
}
