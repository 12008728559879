import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { allbookings, pastbookings } from '../fixtures/operationsbookings'
import { getOperations } from '../services/OperationsService'

export class OperationsBookings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open_cancel: false,
            all_labels: [],
            past_labels: [],
            allbookings: [],
            pastbookings: [],
        }
    }

    componentDidMount() {
        let self = this
        getOperations()
            .then(function(response) {
                console.log(response)
                self.setState({
                    all_labels: allbookings.labels,
                    past_labels: pastbookings.labels,
                    allbookings: response.data,
                    pastbookings: response.data,
                })
            })
            .catch(function(error) {
                console.log(error)
                self.setState({
                    all_labels: allbookings.labels,
                    past_labels: pastbookings.labels,
                    allbookings: allbookings.data,
                    pastbookings: pastbookings.data,
                })
            })
    }

    toggleCancelDialog = () => {
        this.setState({
            open_cancel: !this.state.open_cancel,
        })
    }

    getRadio = () => {
        return (
            <Radio
                checked={true}
                value="a"
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
            />
        )
    }

    getAllBookings = () => {
        const {
            all_labels,
            past_labels,
            allbookings,
            pastbookings,
        } = this.state
        if (!allbookings.length) {
            return <CircularProgress />
        }
        return (
            <Table className="booking-table">
                <TableHead>
                    <TableRow>
                        {all_labels.map((label, index) => {
                            return (
                                <TableCell className="" key={label.id || index}>
                                    <span className="">{label.label}</span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allbookings.map((row, index) => (
                        <TableRow key={row.id || index}>
                            {all_labels.map(label => {
                                if (
                                    label.id === 'booking_received' ||
                                    label.id === 'operations'
                                ) {
                                    return (
                                        <TableCell key={label.id || index}>
                                            {this.getRadio()}
                                        </TableCell>
                                    )
                                }
                                if (label.id === 'confirm_refuse') {
                                    return (
                                        <TableCell key={label.id || index}>
                                            <div className="table-btn-container">
                                                <Button
                                                    className="table-btn-green"
                                                    onClick={
                                                        this.toggleCancelDialog
                                                    }
                                                    variant="contained"
                                                >
                                                    Confirm
                                                </Button>
                                                <Button
                                                    className="table-btn-red"
                                                    onClick={
                                                        this.toggleCancelDialog
                                                    }
                                                    variant="contained"
                                                >
                                                    Refuse
                                                </Button>
                                            </div>
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={label.id || index}>
                                        <span className="">
                                            {row[label.id]}
                                        </span>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    getPastBookings = () => {
        const {
            all_labels,
            past_labels,
            allbookings,
            pastbookings,
        } = this.state
        if (!allbookings.length) {
            return <CircularProgress />
        }
        return (
            <Table className="booking-table">
                <TableHead>
                    <TableRow>
                        {past_labels.map((label, index) => {
                            return (
                                <TableCell className="" key={label.id || index}>
                                    <span className="">{label.label}</span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pastbookings.map((row, index) => (
                        <TableRow key={row.id || index}>
                            {past_labels.map(label => {
                                if (label.id === 'booking_received') {
                                    return (
                                        <TableCell key={label.id || index}>
                                            {this.getRadio()}
                                        </TableCell>
                                    )
                                }
                                if (label.id === 'operations_confirmed') {
                                    return (
                                        <TableCell key={label.id || index}>
                                            <div
                                                className={`not-accepted-label-${
                                                    row[label.id]
                                                }`}
                                            ></div>
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={label.id || index}>
                                        <span className="">
                                            {row[label.id]}
                                        </span>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    render() {
        const {
            all_labels,
            past_labels,
            allbookings,
            pastbookings,
        } = this.state

        return (
            <div className="c-my-booking">
                <div className="table-wrapper t-s">
                    <Typography className="a-l s-v" variant="h4">
                        All Bookings
                    </Typography>
                    {this.getAllBookings()}
                </div>
                <div className="table-wrapper t-s">
                    <Typography className="a-l s-v" variant="h4">
                        Past Bookings
                    </Typography>
                    {this.getPastBookings()}
                </div>
            </div>
        )
    }
}

export default OperationsBookings
