import { makeStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Container,
    Typography,
    Card,
    Fade,
    Divider,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useCallback, useEffect } from 'react'

import { requestPassword } from '../redux/actions/user'
import { resetNotifier } from '../redux/actions/notifier'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: '#fff',
        padding: '2rem',
        borderRadius: '.25rem',
    },
    item: {
        margin: '.5rem',
    },
})

const LoginForm = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const sendRequest = useCallback(
        () => dispatch(requestPassword(username, email)),
        [username, email, dispatch]
    )
    const toggleReminder = () => {
        setOpen(open === true ? false : true)
    }
    const message = useSelector(state => state.user.messages.request)
    useEffect(() => {
        if (message && message.body) {
            setTimeout(() => {
                dispatch(resetNotifier())
                setOpen(false)
            }, 5000)
        }
    }, [message])

    return (
        <Container maxWidth="xs">
            <Grid container>
                <Grid item xs={12}>
                    <Button variant="text" onClick={toggleReminder}>
                        <Typography variant="caption">
                            Forgot password?
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {message && message.body}
                    </Typography>
                    <br />
                    <Divider />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Fade in={open} out={open}>
                        <Card>
                            <form
                                className={classes.container}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    className={classes.item}
                                    required
                                    label="Username"
                                    type="username"
                                    value={username || ''}
                                    onChange={e => setUsername(e.target.value)}
                                />
                                <TextField
                                    className={classes.item}
                                    required
                                    label="Email"
                                    type="email"
                                    value={email || ''}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <Button
                                    className={classes.item}
                                    variant="contained"
                                    color="primary"
                                    onClick={sendRequest}
                                >
                                    send new password
                                </Button>
                            </form>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LoginForm
