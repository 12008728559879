import { parseGetParams, obj2query } from '../utils/Http'
import { jsonCall } from './Gateway'

export const getVts = (filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/vts/booking/list/${parseGetParams({ filter, offset, limit, sort })}`,
        'GET'
    )
    return jwtResp
}
