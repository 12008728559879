import React, { PureComponent } from 'react'
import TopBar from '../components/TopBar'
import Settings from '../components/Settings'

class SettingsPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <TopBar {...this.props} />
                <Settings />
            </div>
        )
    }
}

export default SettingsPage
