const actionsKeys = Object.freeze({
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

    GET_BOOKING: 'GET_BOOKING',
    GET_BOOKING_SUCCESS: 'GET_BOOKING_SUCCESS',
    GET_BOOKING_FAILURE: 'GET_BOOKING_FAILURE',

    GET_BOOKINGS_LIST: 'GET_BOOKINGS_LIST',
    GET_BOOKINGS_LIST_SUCCESS: 'GET_BOOKINGS_LIST_SUCCESS',
    GET_BOOKINGS_LIST_FAILURE: 'GET_BOOKINGS_LIST_FAILURE',

    GET_PAST_BOOKINGS: 'GET_PAST_BOOKINGS',
    GET_PAST_BOOKINGS_SUCCESS: 'GET_PAST_BOOKINGS_SUCCESS',
    GET_PAST_BOOKINGS_FAILURE: 'GET_PAST_BOOKINGS_FAILURE',

    GET_CURRENT_BOOKINGS: 'GET_CURRENT_BOOKINGS',
    GET_CURRENT_BOOKINGS_SUCCESS: 'GET_CURRENT_BOOKINGS_SUCCESS',
    GET_CURRENT_BOOKINGS_FAILURE: 'GET_CURRENT_BOOKINGS_FAILURE',

    CREATE_BOOKING: 'CREATE_BOOKING',
    CREATE_BOOKING_SUCCESS: 'CREATE_BOOKING_SUCCESS',
    CREATE_BOOKING_FAILURE: 'CREATE_BOOKING_FAILURE',

    UPDATE_BOOKING: 'UPDATE_BOOKING',
    UPDATE_BOOKING_SUCCESS: 'UPDATE_BOOKING_SUCCESS',
    UPDATE_BOOKING_FAILURE: 'UPDATE_BOOKING_FAILURE',

    CONFIRM_BOOKING: 'CONFIRM_BOOKING',
    CONFIRM_BOOKING_SUCCESS: 'CONFIRM_BOOKING_SUCCESS',
    CONFIRM_BOOKING_FAILURE: 'CONFIRM_BOOKING_FAILURE',

    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    GET_USERS_LIST: 'GET_USERS_LIST',
    GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
    GET_USERS_LIST_FAILURE: 'GET_USERS_LIST_FAILURE',

    GET_USERS_BY_ROLE: 'GET_USERS_BY_ROLE',
    GET_USERS_BY_ROLE_SUCCESS: 'GET_USERS_BY_ROLE_SUCCESS',
    GET_USERS_BY_ROLE_FAILURE: 'GET_USERS_BY_ROLE_FAILURE',

    BLOCK_USER: 'BLOCK_USER',
    BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
    BLOCK_USER_FAILURE: 'BLOCK_USER_FAILURE',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    ACTIVATE_USER: 'ACTIVATE_USER',
    ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_FAILURE: 'ACTIVATE_USER_FAILURE',

    CREATE_USER: 'CREATE_USER',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    GET_ROLES: 'GET_ROLES',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    NOTIFY_PUSH: 'NOTIFY_PUSH',
    NOTIFY_POP: 'NOTIFY_POP',

    RESET_BOOKING: 'RESET_BOOKING',
    RESET_USER: 'RESET_USER',
    RESET: 'RESET',
    SET_FAKE_ROLE: 'SET_FAKE_ROLE',

    REQUEST_PASSWORD: 'REQUEST_PASSWORD',
    REQUEST_PASSWORD_SUCCESS: 'REQUEST_PASSWORD_SUCCESS',
    REQUEST_PASSWORD_FAILURE: 'REQUEST_PASSWORD_FAILURE',

    SET_EMERGENCY_NUMBER: 'SET_EMERGENCY_NUMBER',
    SET_EMERGENCY_NUMBER_SUCCESS: 'SET_EMERGENCY_NUMBER_SUCCESS',
    SET_EMERGENCY_NUMBER_FAILURE: 'SET_EMERGENCY_NUMBER_FAILURE',

    GET_EMERGENCY_NUMBER: 'GET_EMERGENCY_NUMBER',
    GET_EMERGENCY_NUMBER_SUCCESS: 'GET_EMERGENCY_NUMBER_SUCCESS',
    GET_EMERGENCY_NUMBER_FAILURE: 'GET_EMERGENCY_NUMBER_FAILURE',
})

export default actionsKeys
