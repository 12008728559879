import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useCallback, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {
    TableContainer,
    Paper,
    Toolbar,
    IconButton,
    Tooltip,
    Button,
    ButtonGroup,
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { listUsers } from '../redux/actions/user'
import Config from '../config'

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    Blocked: {
        color: theme.palette.orange[500],
    },
    Deleted: {
        color: theme.palette.red[500],
    },
    Active: {
        color: theme.palette.green[500],
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    toolbarButtons: {
        display: 'flex',
        flexDirection: 'inherit',
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },
    addUserBtn: {
        color: theme.palette.lightBlue[50],
        textDeocration: 'none',
    },
}))

const formatRoleName = name => {
    if (!name) return name
    return name
        .split('_')
        .map(r => `${r.charAt(0).toUpperCase() + r.slice(1)}`)
        .join(' ')
}

const UsersToolbar = props => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [view, setView] = useState('1')

    const handleClick = e => {
        setView(e.currentTarget.name)
        dispatch(listUsers(e.currentTarget.name))
    }

    return (
        <Toolbar className={classes.toolbar}>
            <Tooltip title="Create user">
                <Link to="/u/users/create" className={classes.addUserBtn}>
                    <Button
                        className={classes.addUserBtn}
                        variant="contained"
                        color="primary"
                        aria-label="Create user"
                        startIcon={<PersonAddIcon />}
                    >
                        Add user
                    </Button>
                </Link>
            </Tooltip>
            <div className={classes.toolbarButtons}>
                <ButtonGroup
                    size="small"
                    color="secondary"
                    variant="text"
                    aria-label="text primary button group"
                >
                    <Button
                        {...(view === '1' && {
                            variant: 'text',
                            color: 'primary',
                        })}
                        onClick={handleClick}
                        name="1"
                    >
                        <Typography variant="caption">Active</Typography>
                    </Button>
                    <Button
                        {...(view === '0' && {
                            variant: 'text',
                            color: 'primary',
                        })}
                        onClick={handleClick}
                        name="0"
                    >
                        <Typography variant="caption">Blocked</Typography>
                    </Button>
                    <Button
                        {...(view === '-1' && {
                            variant: 'text',
                            color: 'primary',
                        })}
                        onClick={handleClick}
                        name="-1"
                    >
                        <Typography variant="caption">Deleted</Typography>
                    </Button>
                </ButtonGroup>
            </div>
        </Toolbar>
    )
}

const UsersList = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const users = useSelector(state => state.user.usersList)

    const loadUsers = useCallback(() => {
        dispatch(listUsers(1, null, null, null, null))
    }, [dispatch])

    useEffect(() => {
        loadUsers()
    }, [dispatch, loadUsers])

    const statusLabel = {
        [-1]: 'Deleted',
        [0]: 'Blocked',
        [1]: 'Active',
    }

    return (
        <Grid container direction="column">
            <Grid item xs={12} md={12} lg={8}>
                <UsersToolbar />
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Login type</TableCell>
                                <TableCell align="right">
                                    Phone number
                                </TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users &&
                                users.map(row => (
                                    <TableRow key={row.username}>
                                        <TableCell component="th" scope="row">
                                            <Link
                                                to={`/u/users/${row.user_id}`}
                                            >
                                                {`${row.username} ${
                                                    row.firstname
                                                        ? row.firstname +
                                                          ',' +
                                                          row.lastname
                                                        : ''
                                                }`}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.email}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.role &&
                                                formatRoleName(row.role.name)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.phone_number}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                className={
                                                    classes[
                                                        statusLabel[row.status]
                                                    ]
                                                }
                                            >
                                                {statusLabel[row.status]}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default UsersList
