import _ from 'lodash'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import React, { PureComponent } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { getUsersList } from '../services/UserService'
import Config from '../config'
import role_data from '../fixtures/role_data'
import user_data from '../fixtures/user_data'
import UsersList from '../components/UsersList'

const AdminHome = () => {
    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <UsersList />
            </Grid>
        </Grid>
    )
}

export default AdminHome
