import moment from 'moment-timezone'

export const formatDate = (date) => {
    let dt
    if (date instanceof Date) {
        dt = date
    } else {
        dt = Date.parse(date)
    }
    return moment(dt).tz('Europe/Dublin').format('MMMM Do YYYY, HH:mm')
}
