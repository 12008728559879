import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useCallback, useState } from 'react'
import { jsonCall } from '../services/Gateway'
import { parseGetParams } from '../utils/Http'

import {
    AppBar,
    TextField,
    Grid,
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography,
    Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    createUser,
    updateUser,
    getUser,
    getRoles,
    resetCurrentUser,
    blockUser,
    deleteUser,
    activateUser,
    requestPassword,
} from '../redux/actions/user'
import { push } from '../redux/actions/notifier'
import Notifier from '../components/Notifier'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    btnGroup: {
        margin: theme.spacing(1),
    },
    btnDelete: {
        backgroundColor: theme.palette.red[500],
        color: theme.palette.red[50],
    },
    btnBlock: {
        backgroundColor: theme.palette.orange[500],
        color: theme.palette.black,
    },
    btnActivate: {
        backgroundColor: theme.palette.green[500],
        color: theme.palette.green[50],
    },
    btnResetPassword: {
        color: theme.palette.primary[50],
    },
    a: {
        '& :link': {
            textDecoration: 'none',
        },
    },
}))

const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

const UserFormAdmin = () => {
    const [tab, setTab] = useState(0)
    const { userId } = useParams()
    const classes = useStyles()
    const roles = useSelector(state => state.user.roles)
    const dispatch = useDispatch()
    const userData = useSelector(state => state.user.currentUser)
    const messages = useSelector(state => state.user.messages)
    const history = useHistory()

    // init form data for updates
    useEffect(() => {
        userId && dispatch(getUser(userId))
    }, [dispatch, userId])

    // fill user form or clear for new inserts
    useEffect(() => {
        if (userId) {
            userData &&
                Object.keys(userData).forEach(field => {
                    if (field === 'status') return
                    if (field === 'roleRoleId') {
                        formik.setFieldValue(
                            'role_id',
                            userData[field] || '',
                            false
                        )
                    } else
                        formik.setFieldValue(
                            field,
                            userData[field] || '',
                            false
                        )
                })
        } else {
            formik.resetForm()
            dispatch(resetCurrentUser())
        }
    }, [userData])

    const handleBlock = () => {
        dispatch(blockUser(userId))
    }

    const handleDelete = () => {
        dispatch(deleteUser(userId))
    }

    const handleActivate = () => {
        dispatch(activateUser(userId))
    }

    const handleResetPassword = () => {
        dispatch(requestPassword(userData.username, userData.email))
    }

    // fill roles combo
    useEffect(() => {
        if (!roles || roles.length === 0) {
            dispatch(getRoles())
        }
    }, [dispatch, roles])

    useEffect(() => {
        if (userId) {
            if (messages.update && messages.update.severity) {
                if (messages.update.severity === 'success') {
                    dispatch(resetCurrentUser())
                    formik.resetForm()
                    setTimeout(() => history.push('/u/users'), 500)
                }
            }
        } else {
            if (messages && messages.create && messages.create.severity) {
                if (messages.create.severity === 'success') {
                    dispatch(resetCurrentUser())
                    formik.resetForm()
                    setTimeout(() => history.push('/u/users'), 500)
                }
            }
        }
    }, [messages])

    // region
    const formik = useFormik({
        initialValues: {
            ...(userId ? { user_id: userId } : {}),
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            secondary_email:'',
            tertiary_email:'',
            ...(!userId && { emailMatch: '', password: '', passwordMatch: '' }),
            role_id: '',
            phone_number: '',
        },
        validationSchema: () => {
            return Yup.object({
                firstname: Yup.string(),
                lastname: Yup.string(),
                username: Yup.string().required('Required'),
                email: Yup.string()
                    .email('Invalid email address')
                    .required('Required'),
                ...(!userId && {
                    emailMatch: Yup.string()
                        .required('Email confirmation required')
                        .test('email-match', 'Emails must match', function(
                            value
                        ) {
                            return this.parent.email === value
                        }),
                }),
                secondary_email: Yup.string()
                    .email('Invalid email address'),
                tertiary_email: Yup.string()
                    .email('Invalid email address'),
                phone_number: Yup.string().matches(
                    phoneRegexp,
                    'Phone number is invalid'
                ),
                // validate passwords only if touched
                ...(formik.touched.password && {
                    password: Yup.string()
                        .required('Password required')
                        .min(
                            6,
                            'Password too short - should be 6 characters minimum'
                        ),
                    passwordMatch: Yup.string()
                        .required('Password confirmation required')
                        .test(
                            'password-match',
                            'Passwords must match',
                            function(value) {
                                return this.parent.password === value
                            }
                        ),
                }),
                role_id: Yup.string().required('Required'),
            })
        },
        onSubmit: values => {
            if (userId) {
                const { username, email, secondary_email, tertiary_email,phone_number } = values
                jsonCall(
                    `/user/validate${parseGetParams({
                        userId,
                        username,
                        email,
                        phone_number,
                        secondary_email,
                        tertiary_email,
                    })}`,
                    'GET'
                ).then(resp => {
                    if (resp.success) {
                        const {username, email, phone_number} = resp.data
                        if (username && email && phone_number) {
                            dispatch(updateUser(values))
                        } else {
                            dispatch(
                                push({
                                    body:
                                        'Please, check your form. Username, Email and Phone Number must be unique.',
                                    severity: 'error',
                                })
                            )
                        }
                    }
                })
            } else {
                const { username, email, secondary_email, tertiary_email,phone_number } = values
                jsonCall(
                    `/user/validate${parseGetParams({
                        username,
                        email,
                        phone_number,
                        secondary_email, 
                        tertiary_email,
                    })}`,
                    'GET'
                ).then(resp => {
                    if (resp.success) {
                        const { username, email, phone_number } = resp.data
                        if (username && email && phone_number) {
                            dispatch(createUser(values))
                            formik.resetForm()
                            dispatch(resetCurrentUser())
                        } else {
                            dispatch(
                                push({
                                    body:
                                        'Please, check your form. Username, Email and Phone Number must be unique.',
                                    severity: 'error',
                                })
                            )
                        }
                    }
                })
            }
        },
    })
    // endregion

    return (
        <div className={classes.root}>
            <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                <Grid container spacing={3} direction="column">
                    {/*  roles */}
                    <Grid xs={6} md={4} lg={4} item>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.formControl}
                            >
                                <InputLabel id="user-role-label">
                                    User type
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="user-role-label"
                                    id="role_id"
                                    name="role_id"
                                    error={formik.errors.role_id && true}
                                    {...formik.getFieldProps('role_id')}
                                    {...(userId && { disabled: true })}
                                >
                                    <MenuItem value="">Unassigned</MenuItem>
                                    {roles &&
                                        roles.map(role => (
                                            <MenuItem
                                                key={`role_${role.role_id}`}
                                                disabled={false}
                                                value={role.role_id}
                                            >
                                                {role.name
                                                    .split('_')
                                                    .map(
                                                        r =>
                                                            `${r
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                r.slice(1)}`
                                                    )
                                                    .join(' ')}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>User's roles</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/*  firstname */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                name="firstname"
                                fullWidth
                                label="First name"
                                variant="outlined"
                                margin="dense"
                                autoComplete="name"
                                {...formik.getFieldProps('firstname')}
                            />
                        </Grid>
                    </Grid>
                    {/*  lastname */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                name="lastname"
                                fullWidth
                                label="Last name"
                                variant="outlined"
                                margin="dense"
                                autoComplete="family-name"
                                {...formik.getFieldProps('lastname')}
                            />
                        </Grid>
                    </Grid>

                    {/*  username */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="username"
                                error={formik.errors.username && true}
                                disabled={userId && true}
                                fullWidth
                                name="username"
                                label="username"
                                variant="outlined"
                                margin="dense"
                                {...formik.getFieldProps('username')}
                            />
                        </Grid>
                    </Grid>

                    {/* phone number */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                error={formik.errors.phone_number && true}
                                name="phone_number"
                                fullWidth
                                label="Phone number"
                                variant="outlined"
                                margin="dense"
                                {...formik.getFieldProps('phone_number')}
                            />
                        </Grid>
                    </Grid>

                    {/*  email */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                error={formik.errors.email && true}
                                name="email"
                                fullWidth
                                label="email"
                                variant="outlined"
                                margin="dense"
                                {...formik.getFieldProps('email')}
                            />
                        </Grid>
                        {!userId && (
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.emailMatch && true}
                                    name="emailMatch"
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    placeholder="repeat email"
                                    {...formik.getFieldProps('emailMatch')}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {/*  secondary_email */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                error={formik.errors.secondary_email && true}
                                name="secondary_email"
                                fullWidth
                                label="secondary email"
                                variant="outlined"
                                margin="dense"
                                {...formik.getFieldProps('secondary_email')}
                            />
                        </Grid>
                    </Grid>

                    {/*  tertiary_email */}
                    <Grid item xs={6} md={4} lg={4}>
                        <Grid item xs={12}>
                            <TextField
                                error={formik.errors.tertiary_email && true}
                                name="tertiary_email"
                                fullWidth
                                label="tertiary email"
                                variant="outlined"
                                margin="dense"
                                {...formik.getFieldProps('tertiary_email')}
                            />
                        </Grid>
                    </Grid>

                    {/*  password */}
                    {
                        <Grid item xs={6} md={4} lg={4}>
                            <Grid item xs={12}>
                                <TextField
                                    error={formik.errors.password && true}
                                    name="password"
                                    type="password"
                                    fullWidth
                                    label="password"
                                    variant="outlined"
                                    margin="dense"
                                    autoComplete="new-password"
                                    {...formik.getFieldProps('password')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="passwordMatch"
                                    type="password"
                                    error={formik.errors.passwordMatch && true}
                                    fullWidth
                                    placeholder="repeat password"
                                    variant="outlined"
                                    margin="dense"
                                    autoComplete="new-password"
                                    {...formik.getFieldProps('passwordMatch')}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid item xs={6} md={4} lg={4} className={classes.right}>
                        {userId &&
                            userData &&
                            userData.role &&
                            userData.status !== -1 &&
                            ['ship_owner', 'agent'].indexOf(
                                userData.role.name
                            ) > -1 && (
                                <ButtonGroup className={classes.btnGroup}>
                                    <Button
                                        disabled={userData.status === -1}
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        className={classes.btnDelete}
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        disabled={userData.status === 0}
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        className={classes.btnBlock}
                                        onClick={handleBlock}
                                    >
                                        Block/Inactive
                                    </Button>
                                    <Button
                                        disabled={userData.status === 1}
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        className={classes.btnActivate}
                                        onClick={handleActivate}
                                    >
                                        Activate
                                    </Button>
                                </ButtonGroup>
                            )}
                        <ButtonGroup className={classes.btnGroup}>
                            {false &&
                                userId &&
                                userData &&
                                userData.status !== -1 && (
                                    <Button
                                        className={classes.btnResetPassword}
                                        type="button"
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleResetPassword}
                                    >
                                        Reset password
                                    </Button>
                                )}

                            <Button
                                type="submit"
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
            <Notifier />
        </div>
    )
}

export default UserFormAdmin
