import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import React, { PureComponent } from 'react'
import Typography from '@material-ui/core/Typography'

import TopBar from '../components/TopBar'
import UserFormAdmin from '../components/UserFormAdmin'

class CreateUserPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <TopBar />
                <UserFormAdmin />
            </div>
        )
    }
}

export default CreateUserPage
