import { parseGetParams, obj2query } from '../utils/Http'
import { jsonCall } from './Gateway'

export const getCommercials = (filter, offset, limit, sort) => {
    const jwtResp = jsonCall(
        `/commercial/booking/list/${parseGetParams({
            filter,
            offset,
            limit,
            sort,
        })}`,
        'GET'
    )
    return jwtResp
}
