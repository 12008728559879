import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { getVts } from '../services/VtsService'
import { nextbookings, pastbookings } from '../fixtures/vtsbookings'

export class VtsBookings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open_cancel: false,
            past_labels: [],
            next_labels: [],
            nextbookings: [],
            pastbookings: [],
        }
    }

    componentDidMount() {
        let self = this
        getVts()
            .then(function(response) {
                console.log(response)
                self.setState({
                    next_labels: nextbookings.labels,
                    past_labels: pastbookings.labels,
                    nextbookings: response.data,
                    pastbookings: response.data,
                })
            })
            .catch(function(error) {
                console.log(error)
                self.setState({
                    next_labels: nextbookings.labels,
                    past_labels: pastbookings.labels,
                    nextbookings: nextbookings.data,
                    pastbookings: pastbookings.data,
                })
            })
    }

    getRadio = () => {
        return (
            <Radio
                checked={true}
                value="a"
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
            />
        )
    }

    getNextBookings = () => {
        const { next_labels, past_labels, nextbookings } = this.state
        if (!nextbookings.length) {
            return <CircularProgress />
        }
        return (
            <Table className="booking-table">
                <TableHead>
                    <TableRow>
                        {next_labels.map((label, index) => {
                            return (
                                <TableCell className="" key={label.id || index}>
                                    <span className="">{label.label}</span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {nextbookings.map((row, index) => (
                        <TableRow key={row.id || index}>
                            {next_labels.map(label => {
                                if (
                                    label.id === 'booking_received' ||
                                    label.id === 'operations_confirmed'
                                ) {
                                    return (
                                        <TableCell key={label.id || index}>
                                            {this.getRadio()}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={label.id || index}>
                                        <span className="">
                                            {row[label.id]}
                                        </span>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    getPastBookings = () => {
        const { next_labels, past_labels, pastbookings } = this.state
        if (!pastbookings.length) {
            return <CircularProgress />
        }
        return (
            <Table className="booking-table">
                <TableHead>
                    <TableRow>
                        {past_labels.map((label, index) => {
                            return (
                                <TableCell className="" key={label.id || index}>
                                    <span className="">{label.label}</span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pastbookings.map((row, index) => (
                        <TableRow key={row.id || index}>
                            {past_labels.map(label => {
                                if (
                                    label.id === 'booking_received' ||
                                    label.id === 'operations_confirmed'
                                ) {
                                    return (
                                        <TableCell key={label.id || index}>
                                            {this.getRadio()}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={label.id || index}>
                                        <span className="">
                                            {row[label.id]}
                                        </span>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    render() {
        const {
            next_labels,
            past_labels,
            nextbookings,
            pastbookings,
        } = this.state

        return (
            <div className="c-my-booking">
                <div>
                    <div>
                        <Typography className="a-l s-v" variant="h4">
                            Next Bookings
                        </Typography>
                    </div>
                    <div className="table-wrapper">
                        {this.getNextBookings()}
                    </div>
                </div>
                <div>
                    <div>
                        <Typography className="a-l s-v" variant="h4">
                            Past Bookings
                        </Typography>
                    </div>
                    <div className="table-wrapper">
                        {this.getPastBookings()}
                    </div>
                </div>
            </div>
        )
    }
}

export default VtsBookings
