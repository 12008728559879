import { all, takeEvery, call, put, delay } from 'redux-saga/effects'
import { login, logout } from '../../services/Auth'
import {
    createUser,
    getUser,
    getUsersList,
    updateUser,
    getRoles,
    getUsersByRole,
    blockUser,
    deleteUser,
    activateUser,
    requestPassword,
} from '../../services/UserService'
import {
    createBooking,
    getBooking,
    getBookings,
    updateBooking,
    getPastBookings,
    getCurrentBookings,
    confirmBooking,
} from '../../services/BookingService'

import { push } from '../actions/notifier'

import {
    getEmergencyNumber,
    setEmergencyNumber,
} from '../../services/SettingsService'

import actions from '../actions/actionsKeys'

function* notify(message, severity = 'info') {
    yield put(
        push({
            severity,
            body: message,
        })
    )
}

function* loginCall(action) {
    const { username, password } = action.payload
    try {
        const resp = yield call(login, username, password)
        if (resp.success) {
            yield put({ type: actions.LOGIN_SUCCESS, payload: resp })
        } else {
            yield put({ type: actions.LOGIN_FAILURE, payload: resp })
        }
    } catch (err) {
        yield put({ type: actions.LOGIN_FAILURE, payload: err })
    }
}

function* logoutCall() {
    const resp = yield call(logout)
    yield put({
        type: actions.LOGOUT_SUCCESS,
        payload: resp,
    })
}

function* watchAuth() {
    yield takeEvery([actions.LOGIN, actions.LOGOUT], function*(action) {
        switch (action.type) {
            case actions.LOGIN:
                yield loginCall(action)
                break
            case actions.LOGOUT:
                yield logoutCall()
                break
            default:
                break
        }
    })
}

// BOOKING
function* createBookingCall(action) {
    const body = action.payload
    const resp = yield call(createBooking, body)
    if (resp.success) {
        yield put({ type: actions.CREATE_BOOKING_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.CREATE_BOOKING_FAILURE, payload: resp })
        }
    }
}

function* confirmBookingCall(action) {
    const body = action.payload
    const resp = yield call(confirmBooking, body)
    if (resp.success) {
        yield notify('Booking data has been saved successfully', 'success')
        yield put({ type: actions.CONFIRM_BOOKING_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(action.payload && action.payload.message, 'error')
            yield put({ type: actions.CONFIRM_BOOKING_FAILURE, payload: resp })
        }
    }
}

function* updateBookingCall(action) {
    const body = action.payload
    const resp = yield call(updateBooking, body)
    if (resp.success) {
        yield notify('Booking data has been changed successfully', 'success')
        yield put({ type: actions.UPDATE_BOOKING_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(action.payload && action.payload.message, 'error')
            yield put({ type: actions.UPDATE_BOOKING_FAILURE, payload: resp })
        }
    }
}

function* getBookingsListCall(action) {
    const { filter, offset, limit, sort } = action.payload
    const resp = yield call(getBookings, filter, offset, limit, sort)
    if (resp.success) {
        yield put({
            type: actions.GET_BOOKINGS_LIST_SUCCESS,
            payload: resp.data,
        })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({
                type: actions.GET_BOOKINGS_LIST_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* getBookingCall(action) {
    const { bookingId } = action.payload
    const resp = yield call(getBooking, bookingId)
    if (resp.success) {
        yield put({ type: actions.GET_BOOKING_SUCCESS, payload: resp.data })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.GET_BOOKING_FAILURE, payload: resp.data })
        }
    }
}

function* getPastBookingsCall(action) {
    const { filter, offset, limit, sort } = action.payload
    const resp = yield call(getPastBookings, filter, offset, limit, sort)
    if (resp.success) {
        yield put({
            type: actions.GET_PAST_BOOKINGS_SUCCESS,
            payload: resp.data,
        })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({
                type: actions.GET_PAST_BOOKINGS_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* getCurrentBookingsCall(action) {
    const { filter, offset, limit, sort } = action.payload
    const resp = yield call(getCurrentBookings, filter, offset, limit, sort)
    if (resp.success) {
        yield put({
            type: actions.GET_CURRENT_BOOKINGS_SUCCESS,
            payload: resp.data,
        })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({
                type: actions.GET_CURRENT_BOOKINGS_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* watchBooking() {
    yield takeEvery(
        [
            actions.CREATE_BOOKING,
            actions.UPDATE_BOOKING,
            actions.GET_BOOKINGS_LIST,
            actions.GET_BOOKING,
            actions.GET_PAST_BOOKINGS,
            actions.GET_CURRENT_BOOKINGS,
            actions.CONFIRM_BOOKING,
        ],
        function*(action) {
            switch (action.type) {
                case actions.CONFIRM_BOOKING:
                    yield confirmBookingCall(action)
                    break
                case actions.GET_PAST_BOOKINGS:
                    yield getPastBookingsCall(action)
                    break
                case actions.GET_CURRENT_BOOKINGS:
                    yield getCurrentBookingsCall(action)
                    break
                case actions.CREATE_BOOKING:
                    yield createBookingCall(action)
                    break
                case actions.UPDATE_BOOKING:
                    yield updateBookingCall(action)
                    break
                case actions.GET_BOOKINGS_LIST:
                    yield getBookingsListCall(action)
                    break
                case actions.GET_BOOKING:
                    yield getBookingCall(action)
                    break
                default:
                    break
            }
        }
    )
}

// USER
function* blockUserCall(action) {
    const { userId } = action.payload
    const resp = yield call(blockUser, userId)

    if (resp.success) {
        yield notify('User status is not BLOCKED', 'success')
        yield put({ type: actions.BLOCK_USER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.BLOCK_USER_FAILURE, payload: resp })
        }
    }
}

function* deleteUserCall(action) {
    const { userId } = action.payload
    const resp = yield call(deleteUser, userId)

    if (resp.success) {
        yield notify(
            'Is still possible to read user data in list of deleted users',
            'success'
        )
        yield put({ type: actions.DELETE_USER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.DELETE_USER_FAILURE, payload: resp })
        }
    }
}

function* activateUserCall(action) {
    const { userId } = action.payload
    const resp = yield call(activateUser, userId)

    if (resp.success) {
        yield notify('User status is not ACTIVE', 'success')
        yield put({ type: actions.ACTIVATE_USER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.ACTIVATE_USER_FAILURE, payload: resp })
        }
    }
}

function* requestPasswordCall(action) {
    const { username, email } = action.payload
    const resp = yield call(requestPassword, username, email)
    if (resp.success) {
        yield notify('A new password has been sent to the user', 'success')
        yield put({ type: actions.REQUEST_PASSWORD_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.REQUEST_PASSWORD_FAILURE, payload: resp })
        }
    }
}

function* createUserCall(action) {
    const body = action.payload
    const resp = yield call(createUser, body)
    if (resp.success) {
        yield notify(
            'Data recorded successfully. A confirmation email has been sent',
            'success'
        )
        yield put({ type: actions.CREATE_USER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(resp.message || 'Error saving data.', 'error')
            yield put({ type: actions.CREATE_USER_FAILURE, payload: resp })
        }
    }
}

function* updateUserCall(action) {
    const body = action.payload
    const resp = yield call(updateUser, body)
    if (resp.success) {
        yield notify('User data recorded successfully', 'success')
        yield put({ type: actions.UPDATE_USER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(resp.message || 'Error saving data.', 'error')
            yield put({ type: actions.UPDATE_USER_FAILURE, payload: resp })
        }
    }
}

function* getUsersListCall(action) {
    const { status, filter, offset, limit, sort } = action.payload
    const resp = yield call(getUsersList, status, filter, offset, limit, sort)
    if (resp.success) {
        yield put({ type: actions.GET_USERS_LIST_SUCCESS, payload: resp.data })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({
                type: actions.GET_USERS_LIST_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* getUsersByRoleCall(action) {
    const { id } = action.payload
    const resp = yield call(getUsersByRole, id)
    if (resp.success) {
        yield put({
            type: actions.GET_USERS_BY_ROLE_SUCCESS,
            payload: { data: resp.data, id },
        })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({
                type: actions.GET_USERS_BY_ROLE_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* getRolesCall(action) {
    const resp = yield call(getRoles)
    if (resp.success) {
        yield put({ type: actions.GET_ROLES_SUCCESS, payload: resp.data })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.GET_ROLES_FAILURE, payload: resp.data })
        }
    }
}

function* getUserCall(action) {
    const { userId } = action.payload
    const resp = yield call(getUser, userId)
    if (resp.success) {
        yield put({ type: actions.GET_USER_SUCCESS, payload: resp.data })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield put({ type: actions.GET_USER_FAILURE, payload: resp.data })
        }
    }
}

function* watchUser() {
    yield takeEvery(
        [
            actions.CREATE_USER,
            actions.UPDATE_USER,
            actions.GET_USERS_LIST,
            actions.GET_USERS_BY_ROLE,
            actions.GET_USER,
            actions.GET_ROLES,
            actions.REQUEST_PASSWORD,
            actions.BLOCK_USER,
            actions.DELETE_USER,
            actions.ACTIVATE_USER,
            actions.ACTIVATE_USER,
        ],
        function*(action) {
            switch (action.type) {
                case actions.ACTIVATE_USER:
                    yield activateUserCall(action)
                    break
                case actions.BLOCK_USER:
                    yield blockUserCall(action)
                    break
                case actions.DELETE_USER:
                    yield deleteUserCall(action)
                    break
                case actions.REQUEST_PASSWORD:
                    yield requestPasswordCall(action)
                    break
                case actions.GET_USERS_BY_ROLE:
                    yield getUsersByRoleCall(action)
                    break
                case actions.CREATE_USER:
                    yield createUserCall(action)
                    break
                case actions.UPDATE_USER:
                    yield updateUserCall(action)
                    break
                case actions.GET_USERS_LIST:
                    yield getUsersListCall(action)
                    break
                case actions.GET_USER:
                    yield getUserCall(action)
                    break
                case actions.GET_ROLES:
                    yield getRolesCall(action)
                    break
                default:
                    break
            }
        }
    )
}

// region
function* setEmergencyPhoneCall(action) {
    const { emergency_number } = action.payload
    const resp = yield call(setEmergencyNumber, emergency_number)
    if (resp.success) {
        yield notify(resp.message || 'Data saved successfully.', 'success')
        yield put({ type: actions.SET_EMERGENCY_NUMBER_SUCCESS, payload: resp })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(resp.message || 'Error saving data.', 'error')
            yield put({
                type: actions.SET_EMERGENCY_NUMBER_FAILURE,
                payload: resp,
            })
        }
    }
}
function* getEmergencyPhoneCall(action) {
    const resp = yield call(getEmergencyNumber)
    if (resp.success) {
        yield put({
            type: actions.GET_EMERGENCY_NUMBER_SUCCESS,
            payload: resp.data,
        })
    } else {
        if (resp.code >= 400) {
            yield put({ type: actions.LOGOUT })
        } else {
            yield notify(resp.message || 'Error reading settings.', 'error')
            yield put({
                type: actions.GET_EMERGENCY_NUMBER_FAILURE,
                payload: resp.data,
            })
        }
    }
}

function* watchSettings() {
    yield takeEvery(
        [actions.SET_EMERGENCY_NUMBER, actions.GET_EMERGENCY_NUMBER],
        function*(action) {
            switch (action.type) {
                case actions.SET_EMERGENCY_NUMBER:
                    yield setEmergencyPhoneCall(action)
                    break
                case actions.GET_EMERGENCY_NUMBER:
                    yield getEmergencyPhoneCall(action)
                    break
            }
        }
    )
}
// endregion

export default function* rootSaga() {
    yield all([watchAuth(), watchUser(), watchBooking(), watchSettings()])
}
