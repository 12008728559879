import actions from './actionsKeys'

export const setEmergencyNumber = emergencyPhone => ({
    type: actions.SET_EMERGENCY_NUMBER,
    payload: {
        emergency_number: emergencyPhone,
    },
})

export const getEmergencyNumber = () => ({
    type: actions.GET_EMERGENCY_NUMBER,
})
