import actions from '../actions/actionsKeys'
const initialState = {
    message: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.NOTIFY_PUSH:
            state.message = {
                severity: action.payload.severity,
                body: action.payload.body,
            }
            break
        case actions.RESET:
            if (state.message && state.message.severity) {
                state.message = {}
            }
            break
    }

    return { ...state }
}
