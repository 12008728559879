/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { Route, MemoryRouter } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

const breadcrumbNameMap = {
    '/u/my-bookings': 'Bookings',
    '/u/my-bookings/booking': 'Booking form',
    '/u/my-bookings/details': 'Booking details',
    '/u/my-bookings/booking/sent': 'Booking sent',
    '/u/users': 'Users Management',
    '/u/users/create': 'Add User',
    '/u/users/:userId': 'Edit User',
    '/u/settings': 'Settings',
}

function ListItemLink(props) {
    const { to, open, ...other } = props
    const primary = breadcrumbNameMap[to]

    return (
        <li>
            <ListItem button component={RouterLink} to={to} {...other}>
                <ListItemText primary={primary} />
                {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
        </li>
    )
}

ListItemLink.propTypes = {
    open: PropTypes.bool,
    to: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    lists: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    spacer: {
        margin: theme.spacing(3),
    },
}))

const LinkRouter = props => <Link {...props} component={RouterLink} />

export default function PwtBreadcrumbs() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true)

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen)
    }

    return (
        <div className={classes.root}>
            <Route>
                {({ location }) => {
                    const pathnames = location.pathname
                        .split('/')
                        .filter(x => x)

                    return (
                        <Grid container className={classes.spacer}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <LinkRouter
                                        color="inherit"
                                        to="/u/my-bookings"
                                    >
                                        Home
                                    </LinkRouter>
                                    {pathnames.map((value, index) => {
                                        if (index === 0) return
                                        const last =
                                            index === pathnames.length - 1
                                        const to = `/${pathnames
                                            .slice(0, index + 1)
                                            .join('/')}`

                                        return last ? (
                                            <Typography
                                                color="textPrimary"
                                                key={to}
                                            >
                                                {breadcrumbNameMap[to]}
                                            </Typography>
                                        ) : (
                                            <LinkRouter
                                                color="inherit"
                                                to={to}
                                                key={to}
                                            >
                                                {breadcrumbNameMap[to]}
                                            </LinkRouter>
                                        )
                                    })}
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    )
                }}
            </Route>
        </div>
    )
}
