import { Grid, Divider, Breadcrumbs, Link, Typography } from '@material-ui/core'
import React, { PureComponent } from 'react'

import BookingSheet from '../components/BookingSheet'
import TopBar from '../components/TopBar'

class BookingDetailsPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value,
        })
    }

    render() {
        return (
            <div>
                <TopBar />
                <BookingSheet {...this.props} />
            </div>
        )
    }
}

export default BookingDetailsPage
