import { Link } from 'react-router-dom'
import { Typography, Button, Grid, Card, Divider } from '@material-ui/core'
import React, { PureComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        width: '100%',
        margin: theme.spacing(5),
    },
}))

const BookingDone = () => {
    const classes = useStyles()

    return (
        <Grid container width="xs" className={classes.root}>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <Typography variant="h4">
                        Thank you for placing your booking.
                    </Typography>
                    <Typography>
                        You will receive a confirmation sooner.
                    </Typography>
                    <Divider className={classes.divider} />
                    <Link to="/u/my-bookings">
                        <Button variant="contained" color="primary">
                            Go to Your Bookings
                        </Button>
                    </Link>
                </Card>
            </Grid>
        </Grid>
    )
}

export default BookingDone
