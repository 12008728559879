import React from 'react'
import ReactDOM from 'react-dom'
import { prefetch } from './services/Gateway'
import App from './App'
import register, { unregister } from './registerServiceWorker'

prefetch(() => {
    ReactDOM.render(<App />, document.getElementById('root'))
})

unregister()
//process.env.NODE_ENV === 'development' ? unregister() : register()
