import actions from './actionsKeys'

export const createBooking = body => ({
    type: actions.CREATE_BOOKING,
    payload: body,
})

export const updateBooking = body => ({
    type: actions.UPDATE_BOOKING,
    payload: body,
})

export const deleteBooking = () => ({})

export const listBookings = (filter, offset, limit, sort) => ({
    type: actions.GET_BOOKINGS_LIST,
    payload: {
        filter,
        offset,
        limit,
        sort,
    },
})

export const getPastBookings = (filter, offset, limit, sort) => ({
    type: actions.GET_PAST_BOOKINGS,
    payload: {
        filter,
        offset,
        limit,
        sort,
    },
})

export const getCurrentBookings = (filter, offset, limit, sort) => ({
    type: actions.GET_CURRENT_BOOKINGS,
    payload: {
        filter,
        offset,
        limit,
        sort,
    },
})

export const confirmBooking = body => ({
    type: actions.CONFIRM_BOOKING,
    payload: body,
})

export const getBooking = bookingId => ({
    type: actions.GET_BOOKING,
    payload: {
        bookingId: bookingId,
    },
})

export const resetCurrentBooking = () => ({ type: actions.RESET_BOOKING })
