import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/auth'
import { IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

export const LogoutIconButton = () => {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => {
        dispatch(logout())
    }, [])

    return (
        <IconButton
            edge="end"
            aria-label="Logout"
            aria-controls={0}
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
            title="Settings"
        >
            <ExitToAppIcon />
        </IconButton>
    )
}
