import React, { PureComponent } from 'react'
import BookingDone from '../components/BookingDone.js'
import TopBar from '../components/TopBar.js'
import { Grid } from '@material-ui/core'

export default BookingDonePage => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TopBar />
            </Grid>
            <Grid item xs={12}>
                <BookingDone />
            </Grid>
        </Grid>
    )
}
