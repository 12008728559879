import React, { PureComponent } from 'react'
import TopBar from '../components/TopBar'
import AdminHome from '../components/AdminHome'

const AdminHomePage = () => (
    <div>
        <TopBar />
        <AdminHome />
    </div>
)

export default AdminHomePage
