import actions from './actionsKeys'

export const login = (username, password) => ({
    type: actions.LOGIN,
    payload: {
        username,
        password,
    },
})

export const logout = () => ({
    type: actions.LOGOUT,
})

export const setFakeRole = role => ({
    type: actions.SET_FAKE_ROLE,
    payload: {
        role,
    },
})
