import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'

import { resetNotifier } from '../redux/actions/notifier'

const Notifier = () => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const message = useSelector(state => state.notifier.message)
    useEffect(() => {
        message &&
            message.severity &&
            enqueueSnackbar(message.body, {
                variant: message.severity,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                preventDuplicate: true,
                autoHideDuration: 10000,
            })
        message &&
            message.severity &&
            setTimeout(() => {
                dispatch(resetNotifier())
            }, 1000)
    }, [message])

    return <div></div>
}

export default Notifier
