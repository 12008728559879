import actions from '../actions/actionsKeys'
const initialState = {
    emergency_number: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_EMERGENCY_NUMBER_SUCCESS:
            state.emergency_number = action.payload
            break
    }

    return { ...state }
}
