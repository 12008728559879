import React, { PureComponent } from 'react'
import TopBar from '../components/TopBar'
import VtsBookings from '../components/VtsBookings'

class VtsPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            is_exclusive: props.location.search.match('exclusive'),
        }
    }

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value,
        })
    }

    render() {
        let { is_exclusive } = this.state
        return (
            <div>
                <TopBar
                    {...this.props}
                    is_logged_in={true}
                    tab_value="vts"
                    is_exclusive={is_exclusive}
                />
                <VtsBookings />
            </div>
        )
    }
}

export default VtsPage
