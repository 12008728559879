import { jsonCall } from './Gateway'
import { obj2query } from '../utils/Http'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const login = (username, password) => {
    const jwtResp = jsonCall('/auth/signin', 'POST', { username, password })
    return jwtResp
}

export const logout = () => {
    const jwtResp = jsonCall('/auth/signout', 'POST')
    return jwtResp
}
