import actions from '../actions/actionsKeys'
const initialState = {
    currentUser: {
        userId: null,
        username: null,
        email: null,
        secondary_email:null,
        tertiary_email:null,
        phoneNumber: null,
        lastLogin: null,
        createdAt: null,
        roleId: null,
    },
    usersList: [],
    usersInRole: null,
    roles: null,
    messages: {
        confirm: null,
        create: null,
        update: null,
        request: null,
    },
}

/**
+--------------+--------------+------+-----+---------+----------------+
| Field        | Type         | Null | Key | Default | Extra          |
+--------------+--------------+------+-----+---------+----------------+
| user_id      | int          | NO   | PRI | NULL    | auto_increment |
| username     | varchar(255) | YES  |     | NULL    |                |
| email        | varchar(255) | YES  |     | NULL    |                |
| password     | varchar(255) | YES  |     | NULL    |                |
| phone_number | varchar(255) | YES  |     | NULL    |                |
| last_login   | varchar(255) | YES  |     | NULL    |                |
| createdAt    | datetime     | NO   |     | NULL    |                |
| updatedAt    | datetime     | NO   |     | NULL    |                |
| roleRoleId   | int          | YES  | MUL | NULL    |                |
+--------------+--------------+------+-----+---------+----------------+
 */

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_USER_SUCCESS:
            state.currentUser = action.payload
            break
        case actions.CREATE_USER_SUCCESS:
            const createMsg = {
                create: {
                    severity: 'success',
                    body: 'User has been created!',
                },
            }
            state.messages = { ...state.messages, ...createMsg }
            state.currentUser = { ...initialState.currentUser }
            break
        case actions.CREATE_USER_FAILURE:
            const createErr = {
                create: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }
            state.messages = { ...state.messages, ...createErr }
            break
        case actions.UPDATE_USER_SUCCESS:
            const updateMsg = {
                update: {
                    severity: 'success',
                    body: 'User has been update!',
                },
            }
            state.currentUser = { ...initialState.currentUser }
            state.messages = { ...state.messages, ...updateMsg }
            break
        case actions.UPDATE_USER_FAILURE:
            const updateErr = {
                update: {
                    severity: 'error',
                    body: `Error: ${action.payload.message}`,
                },
            }
            state.messages = { ...state.messages, ...updateErr }
            break
        case actions.GET_USERS_LIST_SUCCESS:
            state.usersList = action.payload
            break
        case actions.GET_USERS_BY_ROLE_SUCCESS:
            const usersInRoleData = {
                usersInRole: { [action.payload.id]: action.payload.data },
            }
            state.usersInRole = { ...state.usersInRole, ...usersInRoleData }
            break
        case actions.GET_ROLES_SUCCESS:
            const roles = { roles: action.payload }
            state = { ...state, ...roles }
            break
        case actions.RESET:
            state = {
                ...state,
                ...{ messages: initialState.messages },
            }
            break
        case actions.RESET_USER:
            state = {
                ...state,
                ...{ currentUser: initialState.currentUser },
            }
            break
        case actions.BLOCK_USER_SUCCESS:
            const blockMsg = {
                update: {
                    severity: 'success',
                    body: 'User blocked',
                },
            }

            state.messages = { ...state.messages, ...blockMsg }
            break
        case actions.BLOCK_USER_FAILURE:
            const blockErr = {
                update: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }

            state.messages = { ...state.messages, ...blockErr }
            break
        case actions.DELETE_USER_SUCCESS:
            const deleteMsg = {
                update: {
                    severity: 'success',
                    body: 'User deleted',
                },
            }

            state.messages = { ...state.messages, ...deleteMsg }
            break
        case actions.DELETE_USER_FAILURE:
            const deleteErr = {
                update: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }

            state.messages = { ...state.messages, ...deleteErr }
            break
        case actions.ACTIVATE_USER_SUCCESS:
            const activateMsg = {
                update: {
                    severity: 'success',
                    body: 'User activated',
                },
            }

            state.messages = { ...state.messages, ...activateMsg }
            break
        case actions.ACTIVATE_USER_FAILURE:
            const activateErr = {
                update: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }

            state.messages = { ...state.messages, ...activateErr }
            break
        case actions.REQUEST_PASSWORD_SUCCESS:
            const requestPasswordMsg = {
                request: {
                    severity: 'success',
                    body: 'Password request has been sent.',
                },
            }

            state.messages = { ...state.messages, ...requestPasswordMsg }
            break
        case actions.REQUEST_PASSWORD_FAILURE:
            const requestPasswordErr = {
                request: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }

            state.messages = { ...state.messages, ...requestPasswordErr }
            break
        default:
            break
    }

    return { ...state }
}
