import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useCallback, useState } from 'react'
import moment from 'moment-timezone'

import {
    AppBar,
    TextField,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Paper,
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { useFormik, useField, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { getRoles, getUsersByRole } from '../redux/actions/user'
import {
    getBooking,
    createBooking,
    updateBooking,
    resetCurrentBooking,
} from '../redux/actions/booking'
import { formatDate } from '../utils/TextHelper'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    center: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    checkboxes: {
        margin: theme.spacing(3),
    },
    formGroup: {
        padding: theme.spacing(1, 3, 1, 3),
    },
    divider: {
        margin: theme.spacing(3),
    },
    disabled: {
        color: '#000',
    },
}))

const overrideStyles = makeStyles((theme) => ({
    root: {
        '&$disabled': {
            color: 'black',
        },
    },
    disabled: {},
}))

const phoneRegexp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// region
const ServiceSelector = ({ formik }) => {
    const classes = useStyles()
    const overrides = overrideStyles()
    const [towin, setTowin] = useState(false)
    const [towout, setTowout] = useState(false)
    const [shift, setShift] = useState(false)

    const formData = useSelector((state) => formik.values)

    useEffect(() => {
        if (formData && formData.towin) setTowin(formData.towin)
        if (formData && formData.towout) setTowin(formData.towout)
        if (formData && formData.shift) setTowin(formData.shift)
    }, [formData])

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <FormControl
                    component="fieldset"
                    className={classes.checkboxes}
                >
                    <FormLabel component="legend">Booked services</FormLabel>
                    <FormGroup>
                        {towin && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled
                                        checked={towin}
                                        name="towin"
                                        classes={{
                                            root: overrides.root,
                                            disabled: overrides.disabled,
                                        }}
                                    />
                                }
                                label={`Tow in at ${formatDate(formData.eta)}`}
                                InputProps={{
                                    classes: {
                                        root: overrides.root,
                                        disabled: overrides.disabled,
                                    },
                                }}
                                classes={{
                                    root: overrides.root,
                                    disabled: overrides.disabled,
                                }}
                            />
                        )}
                        {towout && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled
                                        checked={towout}
                                        name="towout"
                                        classes={{
                                            root: overrides.root,
                                            disabled: overrides.disabled,
                                        }}
                                    />
                                }
                                label={`Tow out at ${formatDate(formData.etd)}`}
                                InputProps={{
                                    classes: {
                                        root: overrides.root,
                                        disabled: overrides.disabled,
                                    },
                                }}
                                classes={{
                                    root: overrides.root,
                                    disabled: overrides.disabled,
                                }}
                            />
                        )}
                        {shift && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled
                                        checked={shift}
                                        name="shift"
                                        classes={{
                                            root: overrides.root,
                                            disabled: overrides.disabled,
                                        }}
                                    />
                                }
                                label={`Shift in at ${formatDate(
                                    formData.shift
                                )}`}
                                InputProps={{
                                    classes: {
                                        root: overrides.root,
                                        disabled: overrides.disabled,
                                    },
                                }}
                                classes={{
                                    root: overrides.root,
                                    disabled: overrides.disabled,
                                }}
                            />
                        )}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}
// endregion

const BookingSheet = () => {
    const classes = useStyles()
    const overrides = overrideStyles()
    const dispatch = useDispatch()
    const [bookingData, setBookingData] = useState(null)
    const bookingState = useSelector((state) => state.booking.currentBooking)
    const history = useHistory()
    const { bookingId } = useParams()

    const {
        role_name: activeRole,
        id: currentUserId,
        firstname,
        lastname,
        username,
    } = useSelector((state) => state.auth.user)

    const currentUserFullName = `${firstname || ''} ${
        lastname || 'no name'
    }, ${username}`

    const NOW = moment(new Date()).tz('Europe/Dublin').toDate()

    useEffect(() => {
        bookingId
            ? dispatch(getBooking(bookingId))
            : dispatch(resetCurrentBooking())
    }, [dispatch, bookingId])

    const formik = useFormik({
        initialValues: {
            ...(bookingId ? { booking_id: bookingId } : {}),
            ship_name: '',
            imo: '',
            flag: '',
            length: '',
            beam: '',
            draft: '',
            gross_tonnage: '',
            net_tonnage: '',
            owner: '',
            client: '',
            towin: '',
            towout: '',
            shift: '',
            eta: NOW,
            etd: NOW,
            est: NOW,
            status: '',
        },
        onSubmit: (values) => {
            return false
        },
    })

    // init booking form from redux state, if set
    useEffect(() => {
        if (bookingState) {
            console.log('INIT BOOKING DATA')
            setBookingData({ ...bookingState })
        }
        return () => dispatch(resetCurrentBooking())
    }, [bookingState])

    useEffect(() => {
        if (bookingData && bookingData.booking_id) {
            for (let k in bookingData) {
                if (k === 'owner') {
                    formik.setFieldValue(
                        k,
                        // prettier-ignore
                        bookingData.shipOwner ? `${bookingData.shipOwner.firstname || 'no name'},${bookingData.shipOwner.lastname || 'no name'} (${bookingData.shipOwner.username}) ` : ''
                    )
                } else {
                    formik.setFieldValue(k, bookingData[k])
                }
            }
        }
    }, [bookingData])

    const txtProps = {
        lg: 5,
        md: 6,
        xs: 12,
        className: classes.formGroup,
    }

    return (
        <div className={classes.root}>
            {bookingData && (
                <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onSubmit={formik.handleSubmit}
                >
                    <Grid item xs={12} md={8} lg={6}>
                        <Paper elevation={3}>
                            <Grid container spacing={3} direction="column">
                                {/* BLOCK 1 */}
                                <Grid
                                    container
                                    className={classes.formGroup}
                                    direction="row"
                                >
                                    {/* owner */}
                                    {activeRole === 'ship_owner' && (
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    name="owner"
                                                    label="Ship owner"
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...formik.getFieldProps(
                                                        'owner'
                                                    )}
                                                    disabled
                                                    classes={{
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                overrides.root,
                                                            disabled:
                                                                overrides.disabled,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {/*  agent */}
                                    {activeRole !== 'ship_owner' && (
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Grid xs={12} item>
                                                <TextField
                                                    fullWidth
                                                    name="agent"
                                                    label="Agent"
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...formik.getFieldProps(
                                                        'owner'
                                                    )}
                                                    disabled
                                                    classes={{
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                overrides.root,
                                                            disabled:
                                                                overrides.disabled,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={12} item>
                                                <TextField
                                                    fullWidth
                                                    name="client"
                                                    label="Ship owner"
                                                    variant="outlined"
                                                    margin="dense"
                                                    {...formik.getFieldProps(
                                                        'client'
                                                    )}
                                                    disabled
                                                    classes={{
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                overrides.root,
                                                            disabled:
                                                                overrides.disabled,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.formGroup}
                                    >
                                        <ServiceSelector formik={formik} />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                {/* BLOCK 2 */}
                                <Grid
                                    container
                                    className={classes.formGroup}
                                    direction="row"
                                >
                                    {/* ship_name */}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.ship_name &&
                                                    true
                                                }
                                                fullWidth
                                                name="ship_name"
                                                label="ship_name"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'ship_name'
                                                )}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/*  imo */}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.imo && true
                                                }
                                                fullWidth
                                                name="imo"
                                                label="imo"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps('imo')}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/*  flag */}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.flag && true
                                                }
                                                fullWidth
                                                name="flag"
                                                label="flag"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'flag'
                                                )}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/*  length */}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.length && true
                                                }
                                                fullWidth
                                                name="length"
                                                label="length"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'length'
                                                )}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            m
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/*  beam */}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.beam && true
                                                }
                                                fullWidth
                                                name="beam"
                                                label="beam"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'beam'
                                                )}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            m
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* draft: 'draft',*/}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.draft && true
                                                }
                                                fullWidth
                                                name="draft"
                                                label="draft"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'draft'
                                                )}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            m
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* gross_tonnage: 'gross_tonnage',*/}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors
                                                        .gross_tonnage && true
                                                }
                                                fullWidth
                                                name="gross_tonnage"
                                                label="gross_tonnage"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'gross_tonnage'
                                                )}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* net_tonnage: 'net_tonnage',*/}
                                    <Grid item {...txtProps}>
                                        <Grid item xs={12}>
                                            <TextField
                                                error={
                                                    formik.errors.net_tonnage &&
                                                    true
                                                }
                                                fullWidth
                                                name="net_tonnage"
                                                label="net_tonnage"
                                                variant="outlined"
                                                margin="dense"
                                                {...formik.getFieldProps(
                                                    'net_tonnage'
                                                )}
                                                disabled
                                                classes={{
                                                    root: overrides.root,
                                                    disabled:
                                                        overrides.disabled,
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* status: 'pending',*/}

                                    {activeRole !== 'admin' && (
                                        <Grid item {...txtProps}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    hidden={true}
                                                    name="status"
                                                    {...formik.getFieldProps(
                                                        'status'
                                                    )}
                                                    disabled
                                                    classes={{
                                                        root: overrides.root,
                                                        disabled:
                                                            overrides.disabled,
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root:
                                                                overrides.root,
                                                            disabled:
                                                                overrides.disabled,
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    {activeRole === 'admin' && (
                                        <Grid item {...txtProps}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    fullWidth
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel id="status-label">
                                                        Booking status
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="status-label"
                                                        id="status"
                                                        name="status"
                                                        {...formik.getFieldProps(
                                                            'status'
                                                        )}
                                                        disabled
                                                        classes={{
                                                            root:
                                                                overrides.root,
                                                            disabled:
                                                                overrides.disabled,
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root:
                                                                    overrides.root,
                                                                disabled:
                                                                    overrides.disabled,
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value="pending"
                                                            InputProps={{
                                                                classes: {
                                                                    root:
                                                                        overrides.root,
                                                                    disabled:
                                                                        overrides.disabled,
                                                                },
                                                            }}
                                                        >
                                                            Pending
                                                        </MenuItem>
                                                        <MenuItem value="confirmed">
                                                            Confirmed
                                                        </MenuItem>
                                                        <MenuItem value="rejected">
                                                            Rejected
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </form>
            )}
        </div>
    )
}

export default BookingSheet
