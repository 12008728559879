import React, { Component, useMemo } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'

import lightBlue from '@material-ui/core/colors/lightBlue'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import store from './redux/store'

import CacheBuster from './components/CacheBuster' ;
import RootPanel from './views/RootPanel'

const App = () => {
    const theme = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    primary: lightBlue,
                    secondary: grey,
                    grey,
                    red,
                    lightBlue,
                    green,
                    orange,
                    white: '#ffffff',
                    black: '#000000',
                },
            }),
        []
    )

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <SnackbarProvider mysnack={5}>
                                <RootPanel />
                            </SnackbarProvider>
                        </Router>
                    </ThemeProvider>
                </Provider>
            }}
        </CacheBuster>
    )
}

export default App
