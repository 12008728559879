import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import SearchIcon from '@material-ui/icons/Search'
import React, { useEffect, useCallback, useState } from 'react'
import { formatDate } from '../utils/TextHelper'

import { getPastBookings, getCurrentBookings } from '../redux/actions/booking'
import { getRoles } from '../redux/actions/user'
import { setFakeRole } from '../redux/actions/auth'

import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    Tooltip,
    IconButton,
    Button,
    ButtonGroup,
    Paper,
    Typography,
    InputBase,
} from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'

import OperationsConfirmButton from './OperationsConfirmButton'
// region
const useStyles = makeStyles((theme) => {
    return {
        pending: {
            color: theme.palette.grey[500],
            fontWeight: 'bold',
            '& td': {
                backgroundColor: theme.palette.grey[50],
            },
        },
        rejected: {
            color: theme.palette.red[500],
            fontWeight: 'bold',
            '& td': {
                backgroundColor: theme.palette.red[50],
            },
        },
        confirmed: {
            color: theme.palette.green[500],
            fontWeight: 'bold',
            '& td': {
                backgroundColor: theme.palette.green[50],
            },
        },
        received: {
            color: theme.palette.lightBlue[500],
            fontWeight: 'bold',
            '& td': {
                backgroundColor: theme.palette.lightBlue[50],
            },
        },
        action: {
            backgroundColor: theme.palette.lightBlue[500],
            color: theme.palette.lightBlue[50],
        },
        roleSelector: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexGrow: 1,
            '& root': {
                fontSize: 'small',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        link: {
            '&:link': {
                textDecoration: 'none',
            },
        },
    }
})
// endregion

// region
export const BookingToolbar = ({ showRoleSelector, type }) => {
    const activeRole = useSelector((state) => state.auth.user.role_name)
    const fakeRole = useSelector((state) => state.auth.fakeRole)
    const classes = useStyles()
    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(getCurrentBookings(e.target.value))
        dispatch(getPastBookings(e.target.value))
    }

    const handleClick = (e) => {
        dispatch(setFakeRole(e.currentTarget.name))
    }

    return (
        <Toolbar>
            {/* New booking button */}
            {type !== 'past' &&
                (fakeRole === 'agent' ||
                    fakeRole === 'ship_owner' ||
                    activeRole === 'ship_owner' ||
                    activeRole === 'agent') && (
                    <Tooltip title="New booking">
                        <Link
                            to="/u/my-bookings/booking"
                            className={classes.link}
                        >
                            <Button
                                variant="contained"
                                className={classes.action}
                                aria-label="new-booking"
                                startIcon={<DirectionsBoatIcon />}
                            >
                                New booking
                            </Button>
                        </Link>
                    </Tooltip>
                )}
            {/* search input */}
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    onChange={handleChange}
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
            <div className={classes.roleSelector}>
                {showRoleSelector && activeRole === 'admin' && (
                    <Typography variant="caption">View as: </Typography>
                )}
                {showRoleSelector && activeRole === 'admin' && (
                    <ButtonGroup
                        size="small"
                        color="secondary"
                        variant="text"
                        aria-label="text primary button group"
                    >
                        <Button
                            {...(fakeRole === 'admin' && {
                                variant: 'text',
                                color: 'primary',
                            })}
                            name="admin"
                            onClick={handleClick}
                        >
                            <Typography variant="caption">Admin</Typography>
                        </Button>
                        <Button
                            {...(fakeRole === 'operations' && {
                                variant: 'text',
                                color: 'primary',
                            })}
                            name="operations"
                            onClick={handleClick}
                        >
                            <Typography variant="caption">
                                Operations
                            </Typography>
                        </Button>
                        <Button
                            {...(fakeRole === 'commercial' && {
                                variant: 'text',
                                color: 'primary',
                            })}
                            name="commercial"
                            onClick={handleClick}
                        >
                            <Typography variant="caption">
                                Commercial
                            </Typography>
                        </Button>
                        <Button
                            {...(fakeRole === 'agent' && {
                                variant: 'text',
                                color: 'primary',
                            })}
                            name="agent"
                            onClick={handleClick}
                        >
                            <Typography variant="caption">Agent</Typography>
                        </Button>
                        <Button
                            {...(fakeRole === 'ship_owner' && {
                                variant: 'text',
                                color: 'primary',
                            })}
                            name="ship_owner"
                            onClick={handleClick}
                        >
                            <Typography variant="caption">Owner</Typography>
                        </Button>
                    </ButtonGroup>
                )}
            </div>
        </Toolbar>
    )
}
// endregion

const BookingsList = (props) => {
    const { type } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const [activeRole, setActiveRole] = useState(null)
    const [rolesMap, setRolesMap] = useState(null)
    const hidden = true

    const bookings = useSelector((state) =>
        type === 'current'
            ? state.booking.currentBookings
            : state.booking.pastBookings
    )
    const roles = useSelector((state) => state.user.roles)

    const messages = useSelector((state) => state.booking.messages)
    const loadBookings = useCallback(() => {
        dispatch(
            type === 'current' ? getCurrentBookings('') : getPastBookings('')
        )
    }, [dispatch])

    const actualRole = useSelector((state) => state.auth.user.role_name)
    const fakeRole = useSelector((state) => state.auth.fakeRole)

    useEffect(() => {
        if (actualRole === 'admin') {
            setActiveRole(fakeRole || actualRole)
        } else {
            setActiveRole(actualRole)
        }
    }, [actualRole, fakeRole])

    useEffect(() => {
        !roles && dispatch(getRoles())
        if (roles) {
            const rmap = roles.reduce((acc, current, idx, arr) => {
                return { ...acc, ...{ [current.role_id]: current.name } }
            }, {})
            setRolesMap(rmap)
        }
    }, [roles])

    useEffect(() => {
        loadBookings()
    }, [dispatch, messages])

    return (
        <Grid container direction="column">
            <Grid item xs={12} md={12} lg={12}>
                {rolesMap && (
                    <TableContainer component={Paper}>
                        <Table aria-label="">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Booking ID</TableCell>
                                    <TableCell>Agent</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Type of service</TableCell>
                                    <TableCell>ETA</TableCell>
                                    <TableCell>ETD</TableCell>
                                    <TableCell>EST</TableCell>
                                    {!hidden && <TableCell>MMSI</TableCell>}
                                    {!hidden && <TableCell>IMO</TableCell>}
                                    {!hidden && <TableCell>Flag</TableCell>}
                                    {!hidden && <TableCell>Length</TableCell>}
                                    {!hidden && <TableCell>Beam</TableCell>}
                                    {!hidden && <TableCell>Draft</TableCell>}
                                    {!hidden && (
                                        <TableCell>Gross tonnage</TableCell>
                                    )}
                                    {!hidden && (
                                        <TableCell>Net tonnage</TableCell>
                                    )}
                                    <TableCell>Status</TableCell>
                                    {activeRole === 'operations' && (
                                        <TableCell></TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookings &&
                                    bookings.map &&
                                    bookings.map((row) => (
                                        <TableRow
                                            key={row.booking_id}
                                            className={
                                                classes[row.status || 'pending']
                                            }
                                        >
                                            <TableCell>
                                                {activeRole === 'admin' && (
                                                    <Link
                                                        to={`/u/my-bookings/booking/${row.booking_id}`}
                                                    >
                                                        {row.booking_id
                                                            .toString()
                                                            .padStart(6, '0')}
                                                    </Link>
                                                )}
                                                {activeRole !== 'admin' && (
                                                    <Link
                                                        to={`/u/my-bookings/details/${row.booking_id}`}
                                                    >
                                                        {row.booking_id
                                                            .toString()
                                                            .padStart(6, '0')}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {rolesMap[
                                                    row.shipOwner.roleRoleId
                                                ] === 'agent' &&
                                                    `${
                                                        row.shipOwner.username
                                                    } (${
                                                        row.shipOwner
                                                            .firstname || ''
                                                    },${
                                                        row.shipOwner
                                                            .lastname || ''
                                                    })`}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    // prettier-ignore
                                                    rolesMap[row.shipOwner.roleRoleId] === 'agent' && row.client
                                                }
                                                {
                                                    // prettier-ignore
                                                    rolesMap[row.shipOwner.roleRoleId] !== 'agent' && `${row.client} (${row.shipOwner.firstname || ''},${row.shipOwner.lastname || ''})`
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {[
                                                    ['Tow in', row.towin],
                                                    ['Tow out', row.towout],
                                                    ['Shift', row.shift],
                                                ]
                                                    .filter((s) => s[1])
                                                    .map((s) => s[0])
                                                    .join(',')}
                                            </TableCell>
                                            <TableCell>
                                                {row.towin &&
                                                    row.eta &&
                                                    formatDate(row.eta)}
                                            </TableCell>
                                            <TableCell>
                                                {row.towout &&
                                                    row.etd &&
                                                    formatDate(row.etd)}
                                            </TableCell>
                                            <TableCell>
                                                {row.shift &&
                                                    row.est &&
                                                    formatDate(row.est)}
                                            </TableCell>
                                            {!hidden && (
                                                <TableCell>
                                                    {row.mmsi}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>{row.imo}</TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.flag}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.length}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.beam}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.draft}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.gross_tonnage}
                                                </TableCell>
                                            )}
                                            {!hidden && (
                                                <TableCell>
                                                    {row.net_tonnage}
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                <Typography
                                                    className={
                                                        classes[row.status]
                                                    }
                                                >
                                                    {row.status}
                                                </Typography>
                                            </TableCell>
                                            {activeRole === 'operations' && (
                                                <TableCell>
                                                    <OperationsConfirmButton
                                                        bookingData={row}
                                                        type={type}
                                                    />
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    )
}

BookingsList.propTypes = {
    type: PropTypes.oneOf(['past', 'current']),
}

export default BookingsList
