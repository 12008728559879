import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { getCommercials } from '../services/CommercialService'
import commercialbookings from '../fixtures/commercialbookings'

export class CommercialBookings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open_cancel: false,
            labels: [],
            commercialbookings: [],
        }
    }

    componentDidMount() {
        let self = this
        getCommercials()
            .then(function(response) {
                console.log(response)
                self.setState({
                    labels: commercialbookings.labels,
                    commercialbookings: response.data,
                })
            })
            .catch(function(error) {
                console.log(error)
                self.setState({
                    labels: commercialbookings.labels,
                    commercialbookings: commercialbookings.data,
                })
            })
    }

    toggleCancelDialog = () => {
        this.setState({
            open_cancel: !this.state.open_cancel,
        })
    }

    getRadio = () => {
        return (
            <Radio
                checked={true}
                value="a"
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
            />
        )
    }

    getCommercialBookings = () => {
        const { labels, commercialbookings } = this.state
        if (!commercialbookings.length) {
            return <CircularProgress />
        }
        return (
            <Table className="booking-table">
                <TableHead>
                    <TableRow>
                        {labels.map((label, index) => {
                            return (
                                <TableCell className="" key={label.id}>
                                    <span className="">{label.label}</span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {commercialbookings.map((row, index) => (
                        <TableRow key={row.id || index}>
                            {labels.map(label => {
                                if (
                                    label.id === 'booking_received' ||
                                    label.id === 'operations_confirmed'
                                ) {
                                    return (
                                        <TableCell key={label.id}>
                                            {this.getRadio()}
                                        </TableCell>
                                    )
                                }
                                if (label.id === 'not_accepted') {
                                    return (
                                        <TableCell key={label.id}>
                                            <div
                                                className={`not-accepted-label-${
                                                    row[label.id]
                                                }`}
                                            ></div>
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell key={label.id}>
                                        <span className="">
                                            {row[label.id]}
                                        </span>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    render() {
        const { labels, commercialbookings } = this.state

        return (
            <div className="c-my-booking">
                <div>
                    <Typography className="a-l s-v" variant="h4">
                        All Bookings
                    </Typography>
                </div>
                <div className="table-wrapper">
                    {this.getCommercialBookings()}
                </div>
            </div>
        )
    }
}

export default CommercialBookings
