import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { throttle } from 'lodash'

import authReducer from './reducers/auth'
import bookingReducer from './reducers/booking'
import userReducer from './reducers/user'
import notifierReducer from './reducers/notifier'
import settingsReducer from './reducers/settings'
import rootSaga from './sagas'
import { loadState, saveState } from '../utils/Storage'
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
    combineReducers({
        auth: authReducer,
        booking: bookingReducer,
        user: userReducer,
        notifier: notifierReducer,
        settings: settingsReducer,
    }),
    loadState(),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
)

// save full app's store to localStorage to keep state after reloading
store.subscribe(
    throttle(() => {
        const state = store.getState()
        saveState(state)
    }, 1000)
)

sagaMiddleware.run(rootSaga)

export default store
