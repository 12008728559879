import actions from '../actions/actionsKeys'
import { getPastBookings } from '../actions/booking'
const initialState = {
    currentBooking: {
        mmsi: null,
        imo: null,
        flag: null,
        length: null,
        beam: null,
        draft: null,
        gross_tonnage: null,
        net_tonnage: null,
        owner: null,
        agent: null,
        towin: null,
        towout: null,
        shift: null,
        eta: null,
        etd: null,
        est: null,
    },
    bookingsList: [],
    pastBookings: [],
    currentBookings: [],
    messages: {
        confirm: null,
        create: null,
        update: null,
    },
}

/**
+---------------+--------------+------+-----+---------+----------------+
| Field         | Type         | Null | Key | Default | Extra          |
+---------------+--------------+------+-----+---------+----------------+
| booking_id    | int          | NO   | PRI | NULL    | auto_increment |
| mmsi          | varchar(255) | YES  |     | NULL    |                |
| imo           | varchar(255) | YES  |     | NULL    |                |
| flag          | varchar(255) | YES  |     | NULL    |                |
| length        | varchar(255) | YES  |     | NULL    |                |
| beam          | varchar(255) | YES  |     | NULL    |                |
| draft         | varchar(255) | YES  |     | NULL    |                |
| gross_tonnage | varchar(255) | YES  |     | NULL    |                |
| net_tonnage   | varchar(255) | YES  |     | NULL    |                |
| owner         | varchar(255) | YES  |     | NULL    |                |
| agent         | varchar(255) | YES  |     | NULL    |                |
| towin         | varchar(255) | YES  |     | NULL    |                |
| towout        | varchar(255) | YES  |     | NULL    |                |
| shift         | varchar(255) | YES  |     | NULL    |                |
| eta           | varchar(255) | YES  |     | NULL    |                |
| etd           | varchar(255) | YES  |     | NULL    |                |
| est           | varchar(255) | YES  |     | NULL    |                |
| createdAt     | datetime     | NO   |     | NULL    |                |
| updatedAt     | datetime     | NO   |     | NULL    |                |
+---------------+--------------+------+-----+---------+----------------+
 */

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_BOOKING_SUCCESS:
            state.currentBooking = action.payload
            break
        case actions.GET_BOOKINGS_LIST_SUCCESS:
            state.usersList = action.payload
            break
        case actions.GET_PAST_BOOKINGS_SUCCESS:
            state.pastBookings = action.payload
            break
        case actions.GET_CURRENT_BOOKINGS_SUCCESS:
            state.currentBookings = action.payload
            break
        case actions.CREATE_BOOKING_SUCCESS:
            const createMsg = {
                create: {
                    severity: 'success',
                    body: 'Booking has been created!',
                },
            }
            state.messages = { ...state.messages, ...createMsg }
            state.currentBooking = { ...initialState.currentBooking }

            break
        case actions.CREATE_BOOKING_FAILURE:
            const createErr = {
                create: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }
            state.messages = { ...state.messages, ...createErr }

            break
        case actions.UPDATE_BOOKING_SUCCESS:
            const updateMsg = {
                update: {
                    severity: 'success',
                    body: 'Booking changes has been recorded.',
                },
            }
            state.messages = { ...state.messages, ...updateMsg }
            state.currentBooking = { ...initialState.currentBooking }
            break
        case actions.UPDATE_BOOKING_FAILURE:
            const updateErr = {
                update: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }
            state.currentBooking = {
                ...initialState.currentBooking,
                ...updateErr,
            }

            break
            break
        case actions.CONFIRM_BOOKING_SUCCESS:
            state.messages = {
                ...state.messages,
                confirm: {
                    severity: 'success',
                    body: action.payload.message,
                },
            }
            break
        case actions.CONFIRM_BOOKING_FAILURE:
            state.messages = {
                ...state.messages,
                confirm: {
                    severity: 'error',
                    body: action.payload.message,
                },
            }
            break
        case actions.RESET:
            state.messages = { ...initialState.messages }
            break
        case actions.RESET_BOOKING:
            state.messages = { ...initialState.messages }
            state.currentBooking = null
        default:
            break
    }

    return { ...state }
}
