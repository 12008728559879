import actions from './actionsKeys'

export const push = message => ({
    type: actions.NOTIFY_PUSH,
    payload: message,
})

export const pop = () => ({
    type: actions.NOTIFY_POP,
})

export const resetNotifier = () => ({ type: actions.RESET })
