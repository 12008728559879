import actions from '../actions/actionsKeys'
const initialState = {
    isLoggedIn: null,
    user: null,
    fakeRole: null,
}

export default (auth = initialState, action) => {
    switch (action.type) {
        case actions.LOGOUT_SUCCESS:
            auth = initialState
            auth.isLoggedIn = false
            auth.user = null
            localStorage.clear()
            window.location.replace('/login')
            break
        case actions.LOGIN_FAILURE:
            auth.isLoggedIn = false
            auth.user = null
            window.location.replace('/login/failure')
            break
        case actions.LOGIN_SUCCESS:
            auth = {
                isLoggedIn: true,
                user: action.payload.data ? action.payload.data : null,
            }
            break
        case actions.SET_FAKE_ROLE:
            auth.fakeRole = action.payload.role
            break
        default:
            break
    }

    return { ...auth }
}
